import Link, { LinkProps } from 'next/link';
import React from 'react';

import { EB2BrokerExpoType } from './TextExpoWebinarGroup.constants';
import { ReactComponent as IconCalendar } from '../../../../public/icons/components/textExpoWebinar/icon-calendar.svg';
import { ReactComponent as IconArrow } from '../../../../public/icons/components/textExpoWebinar/icon-chevron-right.svg';
import { ReactComponent as IconMicrophone } from '../../../../public/icons/components/textExpoWebinar/icon-microphone.svg';

export type TTextExpoWebinar = {
  title: string;
  cover: {
    src: string;
    alt: string;
  } | null;
  link: LinkProps;
  date: string;
  location?: string;
  dateStart: string;
  dateEnd: string;
  type: EB2BrokerExpoType;
};

export type TMonthGroupProps = {
  month: string;
  newsItems: TTextExpoWebinar[];
};

export const TextExpoWebinarGroup: React.FC<TMonthGroupProps> = ({
  month,
  newsItems,
}) => {
  const getDay = (dateString: string): number => {
    const date = new Date(dateString);
    return date.getDate();
  };

  const formatDateRange = (start: string, end: string): string => {
    const startDay = getDay(start);
    const endDay = end ? getDay(end) : '';
    return endDay ? `${startDay}-${endDay}` : `${startDay}`;
  };

  const titleClassName =
    'w-full max-w-full overflow-hidden text-ellipsis text-lg font-medium leading-extra-loose text-interface-1000 lg:whitespace-nowrap lg:pe-4';

  return (
    <div className='mb-[25px] flex flex-col last:mb-0 xl:-mb-[2px] xl:w-full xl:flex-row'>
      <h3 className='mb-[25px] hidden text-lg font-semibold capitalize leading-extra-loose text-interface-1000 lg:block xl:mb-0 xl:w-[10.5%] xl:border-t-2 xl:border-surface-200 xl:pe-4 xl:pt-[25px] 2xl:w-[8.5%]'>
        {month}
      </h3>
      <div className='flex flex-col gap-6 lg:gap-0 lg:border-b-2 lg:border-surface-200 xl:w-[89.5%] 2xl:w-[91.5%]'>
        {newsItems.map(newsItem => (
          <div>
            <div
              className='flex flex-row items-center justify-start gap-1 pb-6 lg:hidden'
              key={newsItem.title}
            >
              <h3 className='text-lg font-semibold capitalize leading-extra-loose text-interface-1000 xl:mb-0 xl:w-[10.5%] xl:border-t-2 xl:border-surface-200 xl:pe-4 xl:pt-[25px] 2xl:w-[8.5%]'>
                {month}
              </h3>
              <p className='text-lg font-medium leading-extra-loose text-interface-1000'>
                {formatDateRange(newsItem.dateStart, newsItem.dateEnd)}{' '}
              </p>
            </div>
            <div className='before:h-100 relative flex flex-row items-center justify-between gap-4 border-y-2 border-surface-200 py-6 before:absolute before:end-[48px] before:start-auto before:h-full before:w-[2px] before:bg-surface-200 before:before:content-[attr(before)] lg:h-[72px] lg:gap-0 lg:border-b-0 lg:py-0 xl:w-full xl:max-w-full'>
              <div className='flex flex-col gap-4 lg:h-full lg:w-full lg:overflow-hidden'>
                <div className='flex flex-row gap-2 lg:h-full lg:items-center lg:gap-0'>
                  <p className='hidden h-full w-[88px] min-w-[88px] items-center whitespace-nowrap border-e-2 border-surface-200 pe-4 text-lg font-medium leading-extra-loose text-interface-1000 lg:flex xl:flex xl:justify-end'>
                    {formatDateRange(newsItem.dateStart, newsItem.dateEnd)}{' '}
                  </p>
                  <div className='h-[24px] w-[24px] min-w-[24px] lg:me-2 lg:ms-4'>
                    {newsItem.type === EB2BrokerExpoType.Webinar ? (
                      <IconMicrophone />
                    ) : (
                      <IconCalendar />
                    )}
                  </div>
                  {newsItem.link ? (
                    <Link className={titleClassName} {...newsItem.link}>
                      {newsItem.title}
                    </Link>
                  ) : (
                    <p className={titleClassName}> {newsItem.title}</p>
                  )}
                  {newsItem.cover && newsItem.cover.src && (
                    <img
                      width={40}
                      height={40}
                      className='hidden h-[40px] w-[40px] min-w-[40px] rounded-[50%] object-cover lg:me-4 lg:block'
                      alt={newsItem.cover.alt}
                      src={newsItem.cover.src}
                      loading='lazy'
                    />
                  )}
                </div>
                {newsItem.location ? (
                  <p className='border-surface-200 ps-8 text-lg font-medium leading-extra-loose text-interface-700 lg:hidden'>
                    {newsItem.location}
                  </p>
                ) : null}
              </div>
              {newsItem.location ? (
                <p className='hidden h-full w-[248px] min-w-[248px] items-center justify-end border-s-2 border-surface-200 p-4 ps-8 text-lg font-medium leading-extra-loose text-interface-700 lg:flex'>
                  {newsItem.location}
                </p>
              ) : null}
              {newsItem.link ? (
                <Link
                  className='flex w-[48px] min-w-[48px] items-center justify-center'
                  {...newsItem.link}
                >
                  <IconArrow className='rtl:rotate-180' />
                </Link>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
